<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-typography-title>Fees & Surcharges</a-typography-title>
      <a-typography-title :heading="6">
        Fees and surcharges can significantly impact your shipping expenses when partnering with
        National Carriers. At
        ADS, we understand the importance of cost control, which is why we strive to minimize fees and maintain lower
        surcharges compared to other providers. Our focus is on providing cost-effective shipping solutions to help you
        save money while ensuring reliable and efficient delivery services.
      </a-typography-title>
      <div class="quick">
        <div @click="goRate" class="item">
          <div class="pic">
            <img src="../../../assets/images/home/jisuan.svg" alt="">
          </div>
          Ground Shipping Rates
        </div>
        <div @click="goFuel" class="item">
          <div class="pic">
            <img src="../../../assets/images/home/ranyou.svg" alt="">
          </div>
          Fuel Surcharge
        </div>
        <div @click="goFees" class="item">
          <div class="pic">
            <img src="../../../assets/images/home/qian.svg" alt="">
          </div>
          Fees
        </div>
      </div>
      <a-typography-title :heading="4" id="rate">
        2023 ADS Ground Shipping Rates
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        With ADS Ground, you can enjoy quicker transit times while still benefiting from rates comparable to those offered
        by National Carriers. We provide the advantage of next-day delivery at affordable ground rates, allowing you to
        receive your shipments promptly without the need for costly expedited shipping options.
      </a-typography-paragraph>
      <a-config-provider :locale="enUS">
        <a-table :columns="baseColumns" :data="baseData" :virtual-list-props="{ height: 428 }" :pagination="false"
          :loading="baseLoading" />
      </a-config-provider>
      <a-typography-title :heading="4" id="fuel">
        Fuel Surcharge
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Fuel Surcharges for our services are linked to market indexes and are reviewed and adjusted on a monthly basis.
        Any changes to the Fuel Surcharge will take effect on the first day of each month. The Fuel Surcharge is
        calculated based on the net package rate, inclusive of applicable Additional Delivery Charges.
        To determine the Fuel Surcharge percentage, we rely on the National U.S. Average On-Highway Diesel Fuel Prices
        published by the U.S. Department of Energy (<a-link href="https://www.eia.gov/petroleum/gasdiesel/"
          target="_blank"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.64122 7.9445C2.25069 8.33503 2.25069 8.96819 2.64122 9.35872C3.03174 9.74924 3.66491 9.74924 4.05543 9.35872L6.17675 7.2374C6.56728 6.84687 6.56728 6.21371 6.17675 5.82318L6.88386 5.11608C7.66491 5.89713 7.66491 7.16346 6.88386 7.9445L4.76254 10.0658C3.98149 10.8469 2.71516 10.8469 1.93411 10.0658C1.15306 9.28478 1.15306 8.01845 1.93411 7.2374L3.17155 5.99996L3.87865 6.70707L2.64122 7.9445ZM5.11609 6.88384C4.33504 6.1028 4.33504 4.83647 5.11609 4.05542L7.23741 1.9341C8.01846 1.15305 9.28479 1.15305 10.0658 1.9341C10.8469 2.71515 10.8469 3.98148 10.0658 4.76252L8.8284 5.99996L8.12129 5.29285L9.35873 4.05542C9.74926 3.66489 9.74926 3.03173 9.35873 2.6412C8.96821 2.25068 8.33504 2.25068 7.94452 2.6412L5.8232 4.76252C5.43267 5.15305 5.43267 5.78621 5.8232 6.17674L5.11609 6.88384Z"
              fill="#165dff" />
          </svg>link</a-link>)
        . The prices used for the adjustment are from two months prior to the adjustment month. For example, the Fuel
        Surcharge for April 2023 is based on the National U.S. Average On-Highway Diesel Fuel Price released by the EIA
        for February 2023.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        Please note that the percentages and thresholds for the Fuel Surcharge are subject to change without prior notice.
        If the Fuel Surcharge falls outside the published range, the range will extend in increments of $0.12, and the
        Fuel Surcharge will adjust in increments of 0.25%.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        We strive to provide transparent and fair pricing, and our Fuel Surcharge methodology ensures that our rates align
        with prevailing fuel costs while maintaining consistency and reliability in our services.
      </a-typography-paragraph>
      <a-config-provider :locale="enUS">
        <a-table :columns="fuelColumns" :data="fuelData" :virtual-list-props="{ height: 428 }" :pagination="false"
          :loading="fuelLoading" />
      </a-config-provider>

      <a-typography-title :heading="4" id="fees">
        ADS Fees
      </a-typography-title>
      <a-typography-title :heading="6">
        Address Correction Fees
        <span>(${{ feesInfo['Address Correction'] }})</span>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        If the address provided to ADS for delivery is found to be incorrect or incomplete, ADS has the authority to make
        necessary corrections or additions to the address using information obtained from the sender or recipient. In such
        cases, an Address Correction Surcharge will be applied to cover the costs associated with addressing the issue.
      </a-typography-paragraph>

      <a-typography-title :heading="6">
        Alternate address pickup
        <span>(${{ feesInfo['Alternate Address Pickup'] }})</span>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        At our logistics company, we strive to provide efficient and reliable services to our valued customers. We
        understand that unexpected changes in pickup addresses can occur, requiring additional coordination and resources.
        To ensure seamless operations, we have implemented a "Alternate Address Pickup" fee. This fee helps cover the
        costs associated with updating the logistics plan, adjusting routes, and reallocating resources to accommodate the
        change. We aim to be transparent about our pricing structure, and this fee enables us to maintain the high level
        of service you expect from us.
      </a-typography-paragraph>
      <a-typography-title :heading="6">
        Residential Delivery Ground
        <span>(${{ feesInfo['Residential Delivery Ground'] }})</span>
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        A surcharge will be added to the delivery fee for any shipment made to a residential address, including businesses
        operating from a residential location.
      </a-typography-paragraph>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { Notification } from '@arco-design/web-vue'
// 引入api中的接口
import { activeBaseCharges, fuelPercentList, additionalHandlingFees } from '@/api/homepage.js'
const baseColumns = reactive([
  {
    title: 'Weight',
    dataIndex: 'weight'
  },
  {
    title: 'Zone2',
    dataIndex: 'zone2'
  },
  {
    title: 'Zone3',
    dataIndex: 'zone3'
  },
  {
    title: 'Zone4',
    dataIndex: 'zone4'
  },
  {
    title: 'Zone5',
    dataIndex: 'zone5'
  },
  {
    title: 'Zone6',
    dataIndex: 'zone6'
  },
  {
    title: 'Zone8',
    dataIndex: 'zone8'
  }
])
const baseData = ref([])
const baseLoading = ref(false)
const getBase = async () => {
  baseLoading.value = true
  const msg = await activeBaseCharges()
  if (msg.code === 0) {
    baseLoading.value = false
    let info = msg.data.GROUND.lb
    for (let k in info) {
      baseData.value.push({
        weight: k + ' lb',
        zone2: '$' + info[k][2],
        zone3: '$' + info[k][3],
        zone4: '$' + info[k][4],
        zone5: '$' + info[k][5],
        zone6: '$' + info[k][6],
        zone7: '$' + info[k][7],
        zone8: '$' + info[k][8]
      })
    }
  } else {
    baseLoading.value = false
    Notification.error('Information acquisition failed.')
  }
}
getBase()

const fuelColumns = reactive([
  {
    title: 'Start At',
    dataIndex: 'startAt'
  },
  {
    title: 'Ground',
    dataIndex: 'ground'
  },
  {
    title: 'Home',
    dataIndex: 'home'
  }
])
const fuelData = ref([])
const fuelLoading = ref(false)
const getFuel = async () => {
  fuelLoading.value = true
  const msg = await fuelPercentList()
  if (msg.code === 0) {
    fuelLoading.value = false
    let info = msg.data
    for (let k in info) {
      fuelData.value.push({
        startAt: k,
        ground: info[k].GROUND.Domestic + '%',
        home: info[k].HOME_DELIVERY.Domestic + '%'
      })
    }
  } else {
    fuelLoading.value = false
    Notification.error('Information acquisition failed.')
  }
}
getFuel()
const feesInfo = ref({})
const feesLoading = ref(false)
const getFees = async () => {
  feesLoading.value = true
  const msg = await additionalHandlingFees()
  console.log(msg)
  if (msg.code === 0) {
    feesLoading.value = false
    feesInfo.value = msg.data
  } else {
    feesLoading.value = false
    Notification.error('Information acquisition failed.')
  }
}
getFees()

// 点击三部分跳转
const goRate = () => {
  let rateTop = document.querySelector('#rate').offsetTop - 70
  window.scrollTo({ top: rateTop, behavior: 'smooth' })
}
const goFuel = () => {
  let rateTop = document.querySelector('#fuel').offsetTop - 70
  window.scrollTo({ top: rateTop, behavior: 'smooth' })
}
const goFees = () => {
  let rateTop = document.querySelector('#fees').offsetTop - 70
  window.scrollTo({ top: rateTop, behavior: 'smooth' })
}
</script>

<style lang="less" scoped>
.arco-table {
  margin-top: 20px;
}

:deep(.arco-virtual-list.arco-table-body) {
  overflow-x: hidden !important;
}

.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku9.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .quick {
    display: flex;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom: 20px;

    .item {
      border-radius: 20px;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
      width: 30%;
      height: 140px;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--color-text-3);

      .pic {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 12px;

        img {
          height: 50px;
          // padding-bottom: 10px;
        }
      }

      &:hover {
        color: rgb(var(--primary-6));
        font-weight: 700;

        .pic {
          border-radius: 50%;
          background: #ECFFF1;
        }
      }
    }
  }
}
</style>
